import Image1 from '../assets/projects/project-image-1.png'

export const projects = [
    {
        number: '01',
        title: 'Цифровая образовательная платфрма ЦОПП',
        description: 'Первый этап создания цифровой образовательной платформы Центра опережающей профессиональной подготовки Ставропольского края',
        slug: 'copp',
        date: '01/10/20',
        media: Image1,
        category: 'education'
    },
    {
        number: '02',
        title: 'Система управления опросами',
        description: 'Информационная система управления опросами через различные каналы коммуникаций',
        slug: 'eventbit',
        date: '31/12/20',
        media: Image1,
        category: 'marketing'
    },
    {
        number: '03',
        title: 'Мобильное приложение "Карта недоступности"',
        description: 'Платформа учета и анализа данных о доступности общественный мест для людей, использующих индивидуальные средства мобильности',
        slug: 'map',
        date: '20/08/21',
        media: Image1,
        category: 'urban'
    },
    {
        number: '04',
        title: 'Цифровая образовательная платфрма ЦОПП',
        description: 'Первый этап создания цифровой образовательной платформы Центра опережающей профессиональной подготовки Ставропольского края',
        slug: 'copp',
        date: '31/12/21',
        media: null,
        category: 'education'
    },
    {
        number: '05',
        title: 'Платформа обучения Английскому языку',
        description: 'Платформа управления контентом, зачисления на образовательные спринты и автоматической проверки заданий',
        slug: 'eis',
        date: '01/03/22',
        media: null,
        category: 'education'
    },
]

export const principles = [
    {
        number: '01',
        title: 'Задача клиента важнее всего',
        description: 'Клиент может озвучить самые смелые идеи, показать необычную реализацию, которая ему лично понравилась — мы выслушаем клиента и предложим различные варианты исполнения.',
    },
    {
        number: '02',
        title: 'Сроки важнее всего',
        description: 'Мы отлично понимаем, что сроки работ — это не пустой звук и, что они не образуются путём случайной генерации чисел дат. Клиент даже в отсутствие каких-либо жёстких требований может смело рассчитывать на решение поставленных задач точно к оговоренному сроку.',
    },
    {
        number: '03',
        title: 'Всегда только живое общение',
        description: 'Если есть необходимость частых встреч для обсуждения технически вопросов, мы всегда «за».',
        link: '/atlas'
    },
    {
        number: '04',
        title: 'Внимательность к мелочам',
        description: 'Клиент вправе ожидать, что даже небольшой его правке, озвученной однажды при встрече, будет уделено должное внимание.',
        link: '/atlas'
    },
]
