export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE"
export const SET_CURRENT_PAGE_REQUESTED = "SET_CURRENT_PAGE_REQUESTED"

export const SHOW_MODAL  = "SHOW_MODAL"
export const SHOW_MODAL_REQUESTED = "SHOW_MODAL_REQUESTED"

export const HIDE_MODAL  = "HIDE_MODAL"
export const HIDE_MODAL_REQUESTED = "HIDE_MODAL_REQUESTED"

export const SET_PAGE_BACKGROUND = "SET_PAGE_BACKGROUND"
export const SET_PAGE_BACKGROUND_REQUESTED = "SET_PAGE_BACKGROUND_REQUESTED"
